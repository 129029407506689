import React from 'react'

export default function Footer() {
  const year = new Date().getFullYear()

  return (
    <div className="footer">
      <div className="footer__copyright">
        {`${year} © All rights reserved. SANA IT SOLUTIONS LLC.`}
        <br />
        <br />
        4855 West Hillsboro Boulevard, B3. Coconut Creek, FL 33073
      </div>
    </div >
  )
}
