import * as Images from 'constants/Images.js'
import projectsData from 'data/projects.json'

function getImageName(slug, index = '') {
  // Split the slug at each hyphen, capitalize each word, and join them back with no space
  const formattedSlug = slug
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')

  return `${formattedSlug}${index ? `Element${index}` : 'Cover'}`
}

const projects = projectsData.map((project) => ({
  ...project,
  cover: Images[getImageName(project.slug)],
  elements: project.elements.map((element, index) => {
    const imageName = element.image ? Images[getImageName(project.slug, index + 1)] : null
    return {
      ...element,
      image: imageName,
    }
  }),
}))

export default projects
