import React from 'react'
import PropTypes from 'prop-types'

import AppStoreIcon from 'assets/icons/app-store.png'
import PlayStoreIcon from 'assets/icons/play-store.png'

function ProjectDetail(props) {
  const { appStore, elements, name, playStore, type, website } = props

  return (
    <div className="project-detail">
      <div className="project-detail__header">
        <h1 className="project-detail__title">{name}</h1>
        <h3 className="project-detail__type">{type}</h3>
      </div>

      {elements.map((element) => (
        <div className="project-detail__element">
          <img src={element.image} alt="" className="project-detail__image" />
          <p className="project-details__description">{element.description}</p>
        </div>
      ))}

      {website && (
        <div className="project-detail__more-info">
          <div>
            <span className="project-detail__see-this">Do you want to see</span>
            &nbsp;
            <span className="project-detail__see-this project-detail__see-this--dark">this?</span>
          </div>
          <div className="project-detail__links">
            <a
              href={website}
              className="project-detail__access-website"
              target="_blank"
              rel="noreferrer"
            >
              Visit Website
            </a>
            {appStore && (
              <a
                href={appStore}
                className="project-detail__store-button project-detail__store-button--app-store"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStoreIcon}
                  className="project-detail__store-icon"
                  alt="App Store logo"
                />
                <span className="project-detail__store-text project-detail__store-text--app-store">
                  Visit App Store
                </span>
              </a>
            )}
            {playStore && (
              <a
                href={playStore}
                className="project-detail__store-button project-detail__store-button--play-store"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PlayStoreIcon}
                  className="project-detail__store-icon"
                  alt="Google Play Store logo"
                />
                <span className="project-detail__store-text project-detail__store-text--play-store">
                  Visit Google Store
                </span>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

ProjectDetail.propTypes = {
  appStore: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  playStore: PropTypes.string,
  type: PropTypes.string.isRequired,
  website: PropTypes.string,
}

ProjectDetail.defaultProps = {
  appStore: '',
  playStore: '',
  website: '',
}

export default ProjectDetail
